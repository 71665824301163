import { Store } from 'src/app/core/store/store';
import { isDevMode } from '@angular/core';

export function getEnv(key?: string): any {
  return key ? window['env'][key] : window['env'];
}

window['getEnv'] = getEnv;

export const environment = {
  production: false,
  staging: false,
  localstorage: {
    key: '3pSync',
  },
  // azAd: {
  //   clientId: getEnv('AZURE_CLIENT_ID'),
  //   tenantId: getEnv('AZURE_TENANT_ID'),
  // },
  api: {
    core: {
      base: "/api",
    } as Store.APIConfig,

    websocket: {
      stomp: {
        endpoint: "/socket/websocket",
      },
    } as Store.APIConfigGroup,

    auth: {
      me: {
        endpoint: "/auth/me",
      },
      validate: {
        endpoint: "/auth/validate",
      },
      forgotPassword: {
        endpoint: "/auth/superadmin/forgot-password",
      },
      verifyOtp: {
        endpoint: "/auth/otp/token",
      },
      verifyEmail: {
        endpoint: "/auth/superadmin/verify-email",
      },
      changePassword: {
        endpoint: "/superadmin/{accountId}/profile/change-password",
      },
    } as Store.APIConfigGroup,

    account: {
      read: {
        endpoint: "/account/{ID}",
      },
      register: {
        endpoint: "/auth/signup",
      },
    } as Store.APIConfigGroup,

    session: {
      login: {
        endpoint: "/auth/signin",
      },
      token: {
        endpoint: "/auth/token",
      },
      tokenRefresh: {
        endpoint: "/token/refresh",
      },
      logout: {
        endpoint: "/auth/logout",
      },
      getVersion: {
        endpoint: "/app-info/get-info",
      },
    },

    overview: {
      getRecentProjects: {
        endpoint: "/project/get-recent-projects",
      },
      getTimeline: {
        endpoint: "/activity-log/get-activity",
      },
      getSummary: {
        endpoint: "/summary/get-summary",
      },
    } as Store.APIConfigGroup,

    projects: {
      getProjectsV2: {
        endpoint: "/project/v2/get-projects?projectType={type}",
      },
      getAllProjects: {
        endpoint: "/project/get-projects",
      },
      getCurrencyTypes: {
        endpoint: "/project/get-currency-types",
      },
      getDraftProjects: {
        endpoint: "/project/get-draft-projects",
      },
      getFavoriteProjects: {
        endpoint: "/project/get-favourite-projects",
      },
      getProjectById: {
        endpoint: "/project/get-project-byId/{id}",
      },
      createProject: {
        endpoint: "/project/add",
      },
      toggleArchival: {
        endpoint: "/project/toggle-archive/{projectId}",
      },
      getProjectLevels: {
        endpoint: "/level/get-levels-for-project/{project_id}",
      },
      bookmarkProject: {
        endpoint: "/user/mark-favourite-projects",
      },
      unBookmarkProject: {
        endpoint: "/user/unmark-favourite-projects",
      },
      getArchivalMaster: {
        endpoint: "/archival/get-archivals",
      },
      getArchivalMasterV2: {
        endpoint: "/archival/get-archivals-v2",
      },
      getContentBudget: {
        endpoint: "/content-budget/get-budget",
      },
      editContentBudget: {
        endpoint: "/content-budget/update-budget",
      },
      exportContentBudget: {
        endpoint:
          "/content-budget/export-contentbudget?projectId={projectId}&levelId={levelId}&format={format}",
      },
      getProjectRepository: {
        endpoint: "/repository/get-repository",
      },
      getProjectTypes: {
        endpoint: "/project/get-project-types",
      },
      getProjectPlatforms: {
        endpoint: "/project/get-platforms",
      },
    } as Store.APIConfigGroup,

    edlMaster: {
      getEDLVersion: {
        endpoint: "/edl-version/get-edl-version",
      },
      getScreengrabProgress: {
        endpoint: "/edl/screen-grabs-progress-sse",
      }, 
      getScreengrab: {
        endpoint: "/edl/get-signed-imagelink",
      },
      getFramerate: {
        endpoint: "/edl-version/get-framerate",
      },
      getBitc: {
        endpoint: "/edl/run-bitc/{project_id}",
      },
      triggerScreengrab: {
        endpoint: "/edl/trigger-screengrab",
      },
      getEDLDetails: {
        endpoint: "/edl/get-edl",
      },
      addEDL: {
        endpoint: "/edl/add-edl-entry",
      },
      addEDLV3: {
        endpoint: "/edl/add-edl-v3",
      },
      getHierarchy: {
        endpoint: "/edl/is-hierarchy-needed",
      },
      getEDLLevels: {
        endpoint: "/level/get-levels-for-project",
      },
      addnewLevel: {
        endpoint: "/level/add",
      },
      updateEdlDetails: {
        endpoint: "/edl/update-edl",
      },
      updateMedia: {
        endpoint: "/edl/update-media",
      },
      exportEdl: {
        endpoint: "/edl/export-edl?format={format}",
      },
      deleteEdlRow: {
        endpoint: "/edl/delete-edl-entry/{id}",
      },
      deleteBulk: {
        endpoint: "/edl/delete-edl-bulk",
      },
    } as Store.APIConfigGroup,

    users: {
      getAllUsers: {
        endpoint: "/user/get-all-by-org",
      },
      getRoles: {
        endpoint: "/role/getAll",
      },
      setProfiePic: {
        endpoint: "/user/set-profile-photo",
      },
      getProfileInfo: {
        endpoint: "/user/current-user",
      },
      getUserProfilePic: {
        endpoint: "/user/get-signed-profileLink",
      },
      updateUser: {
        endpoint: "/user/update-user",
      },
      resetPassword: {
        endpoint: "/user/reset-password",
      },
    } as Store.APIConfigGroup,

    archivalMaster: {
      addAssetToArchival: {
        endpoint: "/archival/add-assets-v2",
      },
      addAssetMetadata: {
        endpoint: "/archival/add-assets-metadata",
      },

      uploadAssetFiles: {
        endpoint: "/file/upload-files",
      },

      confirmAssetUpload: {
        endpoint: "/archival/add-assets-s3",
      },

      addAssetExcel: {
        endpoint: "/archival/add-assets-excel",
      },

      completeMultipart: {
        endpoint: "/file/complete-multipart-upload",
      },
      getPresignedUrl: {
        endpoint:
          "/file/generate-multipart-upload-presigned-urls?fileName={fileName}&fileSize={fileSize}",
      },
      uploadProgress: {
        endpoint: "/file/multiple-upload-progress-sse",
      },

      downloadMetaDataAssets: {
        endpoint: "/archival/download-metadata/{project_id}",
      },
      getCostTypeDropdown: {
        endpoint: "/archival/get-cost-types",
      },
      addDataToArchival: {
        endpoint: "/archival/add-archival",
      },
      UpdateArchival: {
        endpoint: "/archival/update-archival",
      },
      addEpisodeToArchival: {
        endpoint: "/level/add",
      },
      updateEpisodeToArchival: {
        endpoint: "/level/update",
      },
      exportArchival: {
        endpoint: "/archival/export-archive/{project_id}?format={format}",
      },
      getVendorList: {
        endpoint: "/media-source/get-mediasource/{project_id}",
      },
      getVendorSortList: {
        endpoint: "/media-source/get-mediasource",
      },
      getStatusList: {
        endpoint: "/status-master/get-status?type=Archival",
      },
      getAssetPlatformList: {
        endpoint: "/archival/get-platform",
      },
      addNewVendor: {
        endpoint: "/media-source/add-mediasource",
      },
      editVendor: {
        endpoint: "/media-source/update-mediasource",
      },
      addNewStatus: {
        endpoint: "/status-master/add-status",
      },
      deleteArchivalRow: {
        endpoint: "/archival/delete-archival/{id}",
      },
      bulkDownloadAssets: {
        endpoint: "/archival/download-assets/{project_id}",
      },
      bulkDownloadSelectedAssets: {
        endpoint: "/archival/export-archive-bulk-async",
      },
      bulkDeleteSelectedAssets: {
        endpoint: "/archival/delete-archival-bulk",
      },
      assetLink: {
        endpoint: "/archival/get-signed-assetlink/{asset_id}",
      },
      costCheck: {
        endpoint: "/archival/cost-check/{project_id}",
      },
    } as Store.APIConfigGroup,

    releases: {
      formTypes: {
        endpoint: "/release-forms/types",
      },
      paidTypes: {
        endpoint: "/release-forms/paid-types",
      },
      addReleaseVersion: {
        endpoint: "/release-versions/create",
      },
      getReleaseVersion: {
        endpoint: "/release-versions",
      },
      getReleaseForm: {
        endpoint: "/release-forms",
      },
      addAppearance: {
        endpoint: "/release-forms/create",
      },
      updateForm: {
        endpoint: "/release-forms/{id}",
      },
      createReleaseForm: {
        endpoint: "/release-forms/v2/create"
      },
      updateReleaseForm: {
        endpoint: "/release-forms/v2/{id}"
      },
      deleteForm: {
        endpoint: "/release-forms/delete/{type}",
      },
      getScreenGrabs: {
        endpoint: "/release-forms/get-signed-screengrab-links",
      },
      getDocumentLinks: {
        endpoint: "/release-forms/get-signed-document-link/{documentId}",
      },
    } as Store.APIConfigGroup,
    addBulkRow: {
      addBulkData: {
        endpoint: "/archival/add-archival-bulk",
      },
    } as Store.APIConfigGroup,
  },

  formats: {
    date: {
      // Moment JS date formats
      default: 'MM-DD-YYYY',
      long: 'D-MMM-YYYY',
      short: 'D-MMM-YYYY',
    },
    dateTime: {
      // Moment JS date formats
      default: 'MM-DD-YYYY HH:mm:ss',
    },
  },
};

if (isDevMode()) {
  environment.api.core.base = 'https://uatbackend.3psync.com/api';
}
